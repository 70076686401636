import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { SignInWithPassword } from "@platform-app/app/core/auth/auth.state";
import { OrganizationInfoState } from "@platform-app/app/main/organization-settings-page/profile-settings-page/organization-info.state";
import { Subject, takeUntil, tap } from "rxjs";

export interface UIPreferencesStateModel {
  activeTrialBanner: boolean;
  onboardingVisible: boolean;
  floatedOnboardingVisible: boolean;
  onboardingShowDate: Date | undefined;
}

export class HideGuide {
  static readonly type = "[Onboarding Guide] Hide Guide";
}

export class CheckGuideVisibility {
  static readonly type = "[Onboarding Guide] Check Guide visibility";
}

export class CheckFloatedOnboardingGuideVisibility {
  static readonly type = "[Onboarding Guide] Check Floated Guide visibility";
}

export class CollapseSideNav {
  static readonly type = "[Navigation Sidebar] Collapse Sidebar";
}

export class SetActiveTrialBanner {
  static readonly type = "[Trial Banner] Set Banner";
}

export class CloseActiveTrialBanner {
  static readonly type = "[Trial Banner] Close Banner";
}

@State<UIPreferencesStateModel>({
  name: "Preferences",
  defaults: {
    activeTrialBanner: true,
    onboardingVisible: true,
    floatedOnboardingVisible: true,
    onboardingShowDate: undefined,
  },
})
@Injectable()
export class UIPreferencesState {
  private cancelPrevious$ = new Subject<void>();

  constructor(private store: Store) {}

  @Selector()
  static isBannerVisible(state: UIPreferencesStateModel) {
    return state.activeTrialBanner;
  }

  @Selector()
  static onboardingVisible(state: UIPreferencesStateModel) {
    return state.onboardingVisible;
  }

  @Selector()
  static floatedOnboardingVisible(state: UIPreferencesStateModel) {
    return state.floatedOnboardingVisible;
  }

  @Action(HideGuide)
  hideGuide(ctx: StateContext<UIPreferencesStateModel>) {
    ctx.patchState({
      onboardingVisible: false,
    });

    const guideStatus = this.store.selectSnapshot(
      OrganizationInfoState.onboardingStatus,
    );

    if (!guideStatus?.onboardingCompleted) {
      const showDate = new Date();
      new Date(showDate.setDate(showDate.getDate() + 3));
      ctx.patchState({
        onboardingShowDate: showDate,
      });
    }
  }

  @Action(SetActiveTrialBanner)
  setActiveTrialBanner(ctx: StateContext<UIPreferencesStateModel>) {
    ctx.patchState({
      activeTrialBanner: true,
    });
  }

  @Action(CloseActiveTrialBanner)
  closeActiveTrialBanner(ctx: StateContext<UIPreferencesStateModel>) {
    ctx.patchState({
      activeTrialBanner: false,
    });
  }

  @Action(SignInWithPassword)
  resetPreferences(ctx: StateContext<UIPreferencesStateModel>) {
    ctx.patchState({
      activeTrialBanner: true,
      onboardingVisible: true,
      onboardingShowDate: undefined,
    });
  }

  @Action(CheckGuideVisibility)
  checkOnboardingVisibility(ctx: StateContext<UIPreferencesStateModel>) {
    const state = ctx.getState();
    if (
      !state.onboardingVisible &&
      state.onboardingShowDate &&
      new Date(state.onboardingShowDate) <= new Date()
    ) {
      ctx.patchState({
        onboardingVisible: true,
        onboardingShowDate: undefined,
      });
    }
  }

  @Action(CheckFloatedOnboardingGuideVisibility)
  checkFloatedOnboardingGuideVisibility(
    ctx: StateContext<UIPreferencesStateModel>,
  ) {
    return this.store.select(OrganizationInfoState.onboardingStatus).pipe(
      tap((s) => {
        ctx.patchState({
          floatedOnboardingVisible: s?.onboardingCompleted === false,
        });
      }),
      takeUntil(this.cancelPrevious$),
    );
  }
}
